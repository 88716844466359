<template>
  <v-container>
	<v-container>
		<v-row class="md-12">
			<v-col cols=4>
				<v-btn color="green" style="margin-top:15px;width:100%;" href="/quote">
					Get a Quote
				</v-btn>			
			</v-col>
		</v-row>
		<v-row class="md-12">
			<v-col cols=8>
				<h1>Offices and Commercial Buildings</h1>
			</v-col>
		</v-row>
		<v-row>
			<ul>
				<li>Savings - reduce the load on your air conditioning system by reducing the heat entering via glass.  In winter it reduces heat lost through the windows.  That means you will save money.</li>
				<li>Glare reduction - create a more productive environment by reducing the impact of glare within your work space.</li>
				<li>Cost effective - it starts paying for itself as soon as it is installed.</li>
				<li>Privacy</li>
				<li>Safety and security</li>
			</ul>
		</v-row>		
		<p/>
		<p/>
		<p/>
	
		<v-row>
			<img v-if="selectedImageCommercial" style="width:33%" :src="selectedImageCommercial" alt="">  
			<img v-if="selectedImageCommercial2" style="width:33%" :src="selectedImageCommercial2" alt="">
			<img v-if="selectedImageCommercial3" style="width:33%" :src="selectedImageCommercial3" alt="">
		</v-row>
		
		<v-row class="md-12">
			<v-col cols=8>
				<h1>Commercial Vehicles</h1>
			</v-col>
			<v-col cols=4>
				<v-btn color="primary" style="margin-top:15px;width:100%;" href="/automotive">
					Automotive
				</v-btn>	
			</v-col>
		</v-row>
		<v-row>
			<ul>
				<li>UV Protection</li>
				<li>Glare reduction</li>
				<li>Heat reduction</li>
			</ul>
		</v-row>
		<p/>
	</v-container>
  </v-container>
</template>

<script>
	import services from '@/services/services.js'

  export default {
    name: 'commercial',    
	data: () => ({
		items: [
			'/commercial-4.jpg',
			'/commercial-5.jpg',
			'/commercial-6.jpg',
			'/commercial-7.jpg',
			'/commercial-8.jpg',
			'/commercial-9.jpg',
			'/commercial-10.jpg',
			'/commercial-11.jpg',
		],
		selectedImageCommercial: null,
		selectedImageCommercial2: null,
		selectedImageCommercial3: null,
    }),
	methods: {
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		}
	},	
	created() {
		var viewdata={page:"Commercial","count":1};
		services.updateStats(viewdata) 

		this.selectedImageCommercial = this.randomItem(this.items);
		this.selectedImageCommercial2 = this.selectedImageCommercial;
		this.selectedImageCommercial3 = this.selectedImageCommercial;
		while (this.selectedImageCommercial===this.selectedImageCommercial2)
			this.selectedImageCommercial2 = this.randomItem(this.items);
		while (this.selectedImageCommercial===this.selectedImageCommercial3||this.selectedImageCommercial2==this.selectedImageCommercial3)
			this.selectedImageCommercial3 = this.randomItem(this.items);			
	}
  }
</script>
