<template>
	<v-container>
		<div style="background-color:maroon;color:white">
			Quotes
		</div>
		<v-row>		
			<v-col cols=10>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
					style="margin-bottom: 5px;"
				></v-text-field>
			</v-col>
			<v-col cols=2>
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs1 }">
						<v-btn
							class="mx-2"
							fab
							dark
							color="indigo"
							v-bind="attrs1"
							v-on="on"
							@click.stop="filterDialog = true"
						>
							<v-icon dark>
								mdi-filter
							</v-icon>
						</v-btn>	
					</template>
					<span>Database Filter</span>
				</v-tooltip>		
				<v-tooltip top v-if="filterCriteria!==''">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-2"
							fab
							dark
							color="indigo"
							v-bind="attrs"
							v-on="on"
							@click.stop="resetFilter"
						>
							<v-icon dark>
								mdi-cancel
							</v-icon>
						</v-btn>	
					</template>
					<span>Clear Filter</span>
				</v-tooltip>		
			</v-col>
		</v-row>
		<v-data-table
			v-model="dataItem"
			:headers="headers"
			:items="data"
			:items-per-page="15"
			item-key="_id"
			single-select
			:item-class="itemRowBackground"			
			:search="search"
			@click:row="selectedItem"
			>
				<template v-slot:item.date="{ item }">
					{{ getDisplayDate(item.date) }}
				</template>		
		</v-data-table>		
		
		<v-dialog
			v-model="filterDialog"
			transition="dialog-top-transition"
			max-width="600px"
			persistent
		>
			<template>
				<v-card>
					<v-toolbar
						color="primary"
						dark
					>Database Filter</v-toolbar>
					<v-container>
						<form v-on:submit.prevent>
							<v-text-field
								v-model="filterCriteria"
								label="Word search"
							>
							</v-text-field>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="mx-2"
										fab
										dark
										color="indigo"
										v-bind="attrs"
										v-on="on"
										@click="applyFilter"
										type="submit"
									>
										<v-icon dark>
											mdi-magnify-plus
										</v-icon>
									</v-btn>	
								</template>
								<span>Search</span>
							</v-tooltip>
						</form>
					</v-container>
					<v-toolbar
						dark
						@click="filterDialog = false"
					>
						<v-card-actions class="justify-end" >
							<v-btn
								text
								@click="filterDialog = false"
							>Close</v-btn>
						</v-card-actions>
					</v-toolbar>
				</v-card>
			</template>
		</v-dialog>				
		<div v-if="false">	
			<div style="background-color:maroon;color:white; margin-top:20px;">
				Customer
			</div>		
			<v-row style="margin:0px">
				<v-col v-if="customerRecord.firstName" cols=5>
					<strong>First Name  </strong> {{customerRecord.firstName}}
				</v-col>
				<v-col v-if="customerRecord.lastName" cols=5>
					<strong>Last Name  </strong> {{customerRecord.lastName}}
				</v-col>
				<v-col v-if="customerRecord.phoneNumber" cols=5>
					<strong>Phone Number  </strong>  <i v-if="customerRecord.preferedcontact===0">(preferred)</i> <a style='text-decoration: none !important;' v-bind:href="['tel:' + customerRecord.phoneNumber]">{{customerRecord.phoneNumber}}</a>
				</v-col>
				<v-col v-if="customerRecord.emailAddress" cols=5>
					<strong>Email Address</strong> <i v-if="customerRecord.preferedcontact===1">(preferred)</i> <a style='text-decoration: none !important;' v-bind:href="['mailto:' + customerRecord.emailAddress]">{{customerRecord.emailAddress}}</a>
				</v-col>
				<v-col>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								v-bind="attrs"
								v-on="on"
								@click.stop="customerDialog = true"
							>
								<v-icon dark>
									mdi-account
								</v-icon>
							</v-btn>
						</template>
						<span>Edit Customer</span>
					</v-tooltip>	
				</v-col>
			</v-row>

			
			<v-dialog
				v-model="customerDialog"
				transition="dialog-top-transition"
				max-width="600px"
				persistent
			>
				<template>
					<v-card>
						<v-toolbar
							color="primary"
							dark
						>Customer Details</v-toolbar>
						<customerDetails  :customerDetails="customerRecord"></customerDetails>
						<v-toolbar
							dark
							@click="customerDialog = false"
						>
							<v-card-actions class="justify-end" >
								<v-btn
									text
									@click="customerDialog = false"
								>Close</v-btn>
							</v-card-actions>
						</v-toolbar>
					</v-card>
				</template>
			</v-dialog>			
		</div>
		<div v-if="customerRecord._id">	
			<quoteDetails @updatedQuote="refreshQuotes" :quoteDetails="currentItem" :customerDetails="customerRecord"></quoteDetails>
		</div>
	</v-container>
</template>

<script>
	import services from '@/services/services.js'
	import customerDetails from '@/components/customerDetails.vue'
	import quoteDetails from '@/components/quoteDetails.vue'
	
  export default {
    name: 'getQuote',
	components: {
		customerDetails,
		quoteDetails
	},
    data: () => ({
		customerDialog:false,
		filterDialog:false,
		filterCriteria:'',
		tintItems:[], //list of items that can be tinted from API call at startup
		customerRecord:{},
		search:'',
		users:[],
		dataItem:[],
		data:[],
        headers: [
			{ text: 'Reference', align: 'start', value: 'reference' },
			{ text: 'Status', align: 'start', value: 'status' },
			{ text: 'For', align: 'start', value: 'quoteFor.label' },
			{ text: 'Customer', align: 'start', value: 'customersummary' },
			{ text: 'Rego', align: 'start', value: 'registration' },
			{ text: 'Date', align: 'start', value: 'date' },
			{ text: 'Location', align: 'start', value: 'installText' },
		],
		getaquote:[],
		currentItem:{},
		errors:"",
		errorMessage:"",
    }),
	methods: {
		selection(selection)
		{	
			this.currentItem.quoteFor=selection;
		},
		pad(n) {return n<10 ? "0"+n : n},
		convertUTCDateToLocalDate(date) {
			var newDate = new Date(date);
			return  newDate.toLocaleDateString() + " " + newDate.toLocaleTimeString();   
		},	
		randomItem (items) {
			return items[Math.floor(Math.random()*items.length)];
		},
		selectedChange() {
		},
		selectedItem(item){
			this.filterCriteria=item.reference;
			services.getFilteredQuotes({filter:this.filterCriteria,token:this.$store.state.token}).then(response=>{if (response.data.data) this.data=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
			this.currentItem=item;
			services.getCustomer({id:this.currentItem.customerid,token:this.$store.state.token}).then(response=>{if (response.data.data) this.customerRecord=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
		},
		refreshQuotes()
		{
			this.filterCriteria=this.currentItem.reference;
			services.getFilteredQuotes({filter:this.filterCriteria,token:this.$store.state.token}).then(response=>{if (response.data.data) this.data=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
		},
		addQuote() {
		},
		getDisplayDate(dateEpoch) {
			var displayDate=new Date(dateEpoch);
			var dateString=displayDate.toString("dd/MM/yyyy");
			dateString=dateString.substring(0,dateString.indexOf(" GMT"));
			return dateString;
		},
		applyFilter() {
			this.filterDialog = false;
			services.getFilteredQuotes({filter:this.filterCriteria,token:this.$store.state.token}).then(response=>{if (response.data.data) this.data=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
		},		
		resetFilter() {
			this.filterCriteria="";
			services.getQuotes({token:this.$store.state.token}).then(response=>{if (response.data.data) this.data=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
		},
		itemRowBackground(item) {
			return item.deleted ? 'deletedItem' : ''
		},		
	},	
	computed: {
		accessLevel() {
			return this.$store.state.accessLevel;
		},
		years() {
			var temp=[];
			var now=new Date();
			var last=now;
			for (var i=now.getFullYear()+1;i>=(now.getFullYear()-30);i--)
			{
				temp.push(i);
				last=i;
			}
			temp.push("Older than "+last);

			return temp;
		},
	},
	created() {
		services.getTintQuoting().then(response=>{
			if (response.data.data)
			{
				this.tintItems=response.data.data;
			}
		}).catch(error=> { console.log(error)});

		var config={token:this.$store.state.token};
		services.getQuotes(config).then(response=>{if (response.data.data) this.data=response.data.data;if (response.data.token && response.data.result==="ok") this.$store.commit('setToken',response.data.token); else this.$store.commit('setToken',"");}).catch(error=> { console.log(error)});
		this.fleetCount=[];
		for (var i=1;i<=25;i++)
		{
			this.fleetCount.push(i);
		}
		this.fleetCount.push(">25");		
	},
  }
</script>
<style>
	.deletedItem {
		text-decoration: line-through;
	}
</style>