<template>
	<v-container>        
		<div>
			<div style="background:#efefef;padding: 20px 15px 5px 15px;">
				<div style="display:inline-block;margin-right:16px">
					<h4 style="margin:0;display:inline;">Solartint Penrith</h4>
					<div style="margin-left:15px;display:inline;">14/121 Coreen Ave, Penrith NSW 2750</div>
				</div>   
			</div>   
			<div style="background:#efefef;padding: 0px 15px 20px 15px;">
				<div style="display:inline-block;margin-right:16px">
					<h4 style="margin:0;display:inline;">SGM Penrith</h4>
					<div style="margin-left:15px;display:inline;">14/121 Coreen Ave, Penrith NSW 2750</div>
				</div>   
			</div>   
			<div>
				<iframe
					id="gmap_canvas"
					width="100%"
					height="50%"
					src="https://maps.google.com/maps?q=-33.74424693350726, 150.69967017057132&hl=en;z=11&amp;output=embed"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
				/>
			</div>
		</div>   
		<div>
			<div style="background:#efefef;padding: 20px 15px;">
				<div style="display:inline-block;margin-right:16px">
					<h4 style="margin:0;display:inline;">SGM Seven Hills</h4>
					<div style="margin-left:15px;display:inline;">Unit 25 128 Station Road, Seven Hills NSW 2147</div>
				</div>   
			</div>   
			<div>
				<iframe
					id="gmap_canvas"
					width="100%"
					height="50%"
					src="https://maps.google.com/maps?q=-33.76945724456131, 150.9427147570788&hl=en;z=11&amp;output=embed"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
				/>
			</div>
		</div>   		
		<div>
			<div style="background:#efefef;padding: 20px 15px;">
				<div style="display:inline-block;margin-right:16px">
					<h4 style="margin:0;display:inline;">SGM Mid North Coast</h4>
					<div style="margin-left:15px;display:inline;">12 Yarrawonga Street, Macksville NSW 2447</div>
				</div>   
			</div>   
			<div>
				<iframe
					id="gmap_canvas"
					width="100%"
					height="50%"
					src="https://maps.google.com/maps?q=-30.728039278991783, 152.91306503889496&hl=en;z=11&amp;output=embed"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
				/>
			</div>
		</div>   			
		<div>
			<div style="background:#efefef;padding: 20px 15px;">
				<div style="display:inline-block;margin-right:16px">
					<h4 style="margin:0;display:inline;">SGM Armidale</h4>
					<div style="margin-left:15px;display:inline;">160 Miller Street, Armidale NSW 2350</div>
				</div>   
			</div>   
			<div>
				<iframe
					id="gmap_canvas"
					width="100%"
					height="50%"
					src="https://maps.google.com/maps?q=-30.51538475388427, 151.6477421839664&hl=en;z=11&amp;output=embed"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
				/>
			</div>
		</div>   	 	
		<div>
			<div style="background:#efefef;padding: 20px 15px;">
				<div style="display:inline-block;margin-right:16px">
					<h4 style="margin:0;display:inline;">SGM Inverell</h4>
					<div style="margin-left:15px;display:inline;">56 Oliver Street, Inverell, NSW 2360</div>
				</div>   
			</div>   
			<div>
				<iframe
					id="gmap_canvas"
					width="100%"
					height="50%"
					src="https://maps.google.com/maps?q=-29.7700924, 151.1183381&hl=en;z=11&amp;output=embed"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
				/>
			</div>
		</div>   	 	
	</v-container>
</template>

<script>
	import services from '@/services/services.js'
	
	
	export default {
    name: 'locations',

    data: () => ({
     
    }),
	created() {
		var viewdata={page:"Locations","count":1};
		services.updateStats(viewdata);
	}
  }
</script>
