<template>
	<v-container>
		<v-row>
			<v-col cols=6>
				<v-text-field
					v-model="customerDetails.firstName"
					:counter="40"
					label="First Name"
					required
				></v-text-field>
			</v-col>						
			<v-col cols=6>
				<v-text-field
					v-model="customerDetails.lastName"
					:counter="40"
					label="Last Name"
					required
				></v-text-field>
			</v-col>						
		</v-row>
		<v-row>
			<v-col cols=6>
				<v-text-field
					v-model="customerDetails.emailAddress"
					:counter="40"
					label="Email address"
					required
					prepend-inner-icon="mdi-email"
				></v-text-field>
			</v-col>						
			<v-col cols=6>
				<v-text-field
					v-model="customerDetails.phoneNumber"
					:counter="11"
					label="Phone Number"
					required
					prepend-inner-icon="mdi-phone"
				></v-text-field>	
			</v-col>						
		</v-row>
		<v-container style="margin-bottom:10px; margin-top:10px;">  
			<v-row>
				<div>Preferred contact method</div>
			</v-row>	
			<v-row>
				<v-btn-toggle v-model="customerDetails.preferedcontact">
					<v-btn>
						Phone
					</v-btn>
					<v-btn>
						Email
					</v-btn>
				</v-btn-toggle>		
			</v-row>
		</v-container>

		<v-row>
			<v-col cols=8>
				<v-text-field
					v-model="customerDetails.businessName"
					:counter="100"
					label="Business Name"
					required
				></v-text-field>
			</v-col>
			<v-col cols=4>
				<v-text-field
					v-model="customerDetails.abn"
					:counter="100"
					label="ABN"
					required
				></v-text-field>
			</v-col>
		</v-row>
		<v-text-field
			v-model="customerDetails.streetAddress"
			:counter="100"
			label="Street Address"
			required
		></v-text-field>
		<v-row>
			<v-col cols=4>
				<v-text-field
					v-model="customerDetails.postcode"
					:counter="4"
					label="Postcode"
					required
					@change="postcodeChange"
					autocomplete="chrome-off"
				></v-text-field>					
			</v-col>
			<v-col v-if="customerDetails && customerDetails.postcode && customerDetails.postcode.length===4" cols=8>
				<v-combobox
					v-model="customerDetails.suburb"
					:items="suburbs"
					item-text="suburb"
					hide-selected
					label="Select Suburb"
					autocomplete="chrome-off"
				>
				</v-combobox>
			</v-col>
		</v-row>	
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					class="mx-2"
					fab
					dark
					color="indigo"
					v-bind="attrs"
					v-on="on"
					@click="saveData"
					style="margin-top:5px"
				>
					<v-icon dark>
						mdi-content-save
					</v-icon>
				</v-btn>
			</template>
			<span>Save changes</span>
		</v-tooltip>		
	</v-container>
</template>

<script>
	import services from '@/services/services.js'

	export default {
	props: ['customerDetails'],
	data() {
		return {
			suburbs:[],
		}
    },    
	methods: {
		postcodeChange()
		{
			if (this.customerDetails.postcode.length==4)
			{
				services.getSuburbs(this.customerDetails.postcode).then(response=>{this.suburbs=response.data.suburbs;console.log(this.suburbs);}).catch(error=> { console.log(error)})
				console.log(this.customerDetails.postcode);
			}
		},	
		searchCustomer(){
		},
		newCustomer(){
		},
		saveData(){
			var request={data:this.customerDetails,token:this.$store.state.token};
			services.updateCustomer(request).then(response=>{if (response.data.token) this.$store.commit('setToken',response.data.token);}).catch(error=>{ console.log(error)})
		}
	},
  }
</script>

<style scoped>
</style>